import React from "react"
import Styles from "../styles/components/HomeHero.module.scss"
import Typist from "react-typist"
import CTA from "../components/ctaButton"

const HomeHero = props => {
  return (
    <div className={`${Styles["page-hero-wrapper"]}`}>
      <div className={`container-padding ${Styles.container} `}>
       
        <div className={Styles["content-container"]}>\
          <div className={Styles["hero-text"]}>
            {props.pageTitle && <span>{props.pageTitle}</span>}
            <h1>
            {props.heading}
            </h1>
            <p>{props.description}</p>
            {props.buttonTitle && <CTA buttonTitle={props.buttonTitle} buttonUrl={props.buttonUrl}></CTA>}
            {props.secondaryButtonTitle && <CTA buttonTitle={props.secondaryButtonTitle} buttonUrl={props.secondaryButtonUrl} secondaryButton></CTA>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
