import React from "react"
import HomeHero from "../components/HomeHero"
import HomeSectionOne from "../components/HomeSectionOne"
import SEO from "../components/seo"
import Footer from "../components/footer"
import HomeWorkSection from "../components/HomeWorkSection"
import BrandLogos from "../components/brandlogos"
import BlogSection from "../components/HomeBlogSection"
import CTA from "../components/cta"
import MobileHeader from "../components/MobileHeader"
import Typist from "react-typist"
import TypistLoop from "react-typist-loop"
import TopNav from "../components/header"

const clientGroups = (
  <TypistLoop>
    {["Businesses", "Governments", "People"].map(text => (
      <Typist key={text} startDelay={1000} delay={2000} className="MyTypist">
        <span>{text}</span>
      </Typist>
    ))}
  </TypistLoop>
)

const keyword = "Transform "

const IndexPage = () => (
  <div>
    <MobileHeader></MobileHeader>
    <TopNav />
    <SEO title="Home" />
    <HomeHero
      heading={[
        "We Design and Develop Websites and Apps That ",
        <span key="home-page">{keyword}</span>,
        clientGroups,
      ]}
      description="We help leading organizations leverage the power of technology to solve their pressing operational and innovation challenges"
      imgSrc="/hero-background-test.jpg"
      buttonTitle="New Project"
      buttonUrl="contact"
      secondaryButtonTitle="Free Consultation"
      secondaryButtonUrl="contact"
    ></HomeHero>
    <HomeSectionOne></HomeSectionOne>
    <HomeWorkSection></HomeWorkSection>
    <BrandLogos></BrandLogos>
    <BlogSection></BlogSection>
    <CTA></CTA>
    <Footer></Footer>
  </div>
)

export default IndexPage
