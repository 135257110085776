import React from "react"
import TestLogo from "../images/LOGO 1.png"
import TestLogo2 from "../images/LOGO 2.png"
import TestLogo3 from "../images/LOGO 3.png"
import TestLogo4 from "../images/LOGO 4.png"
import TestLogo5 from "../images/LOGO 5.png"
import TestLogo6 from "../images/LOGO 6.png"
import TestLogo7 from "../images/LOGO 7.png"
import TestLogo8 from "../images/LOGO 8.png"
import TestLogo9 from "../images/LOGO 9.png"
import TestLogo10 from "../images/LOGO 10.png"
import TestLogo11 from "../images/LOGO 11.png"
import TestLogo12 from "../images/LOGO 12.png"
import Styles from "../styles/components/BrandLogos.module.scss"
import ScrollAnimation from "react-animate-on-scroll"

const BrandLogos = () => (
  <div className="container-padding">
    <div className={Styles['intro-text']}>
      Looking forward to adding your brand to our growing list of partners
    </div>
    <div className={Styles["logo-image"]}>
      <img src={TestLogo} alt="test logo" />
      <img src={TestLogo2} alt="test logo" />
      <img src={TestLogo3} alt="test logo" />
    </div>
    <div className={Styles["logo-image"]}>
      <img src={TestLogo4} alt="test logo" />
      <img src={TestLogo5} alt="test logo" />
      <img src={TestLogo10} alt="test logo" />
    </div>
    <div className={Styles["logo-image"]}>
      <img src={TestLogo11} alt="test logo" />
      <img src={TestLogo8} alt="test logo" />
      <img src={TestLogo9} alt="test logo" />
    </div>
    <div className={Styles["logo-image"]}>
      <img src={TestLogo6} alt="test logo" />
      <img src={TestLogo7} alt="test logo" />
      <img src={TestLogo12} alt="test logo" />
    </div>
  </div>
)

export default BrandLogos
