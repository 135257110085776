import React from "react"
import Styles from "../styles/components/HomeSectionOne.module.scss"
import VennDiagram from "../images/venn_diagram.svg"
import ScrollAnimation from "react-animate-on-scroll"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const HomeSectionOne = () => (
  <div
    className={`${Styles["background"]} ${
      Styles["section-container"]
    } container-padding`}
  >
    <div>
      <div className={Styles["section-line--title"]}>
        <h5>Innovation and Transformation</h5>
      </div>
      <div className={Styles["section-divider"]}>
        <div className={Styles["section-divider--text"]}>
          <h1 className={Styles["section-title--big"]}>Leading Growth</h1>
          <ScrollAnimation animateIn="fadeIn">
            <div className={Styles["section-text--divider"]}>
              <p>
                Building an innovative organization in a digital world is
                challenging, customers, operations and business activities now
                function at the speed of thought, becoming a leader and
                maintaining leadership requires innovation, in-and-out of the
                box solutions that leverage digital technology to interact with
                customers and manage operations effectively. <br />
                <br />{" "}
                <strong>
                  We design your solutions with your business goals in mind,
                  impacting performance by solving your operational challenges
                  with technology and/or by solving revenue challenges with new
                  products and better digital experiences.
                </strong>
              </p>
            </div>
          </ScrollAnimation>
        </div>
        <div className={Styles["section-divider--img"]}>
          <ScrollAnimation animateIn="zoomInDown">
            <img src={VennDiagram} alt="venn diagram" />
          </ScrollAnimation>
        </div>
      </div>
      <ScrollAnimation animateIn="fadeIn">
        <div className={Styles["services-subsection"]}>
          <AniLink
            to="/expertise/websites"
            className={Styles["services-subsection--service"]}
          >
            <h3>Websites</h3>
            <li>Website Strategy</li>
            <li>Web Design</li>
            <li>Web Development</li>
            <li>Launch Campaign Strategy</li>
            <li>Continuous Enhancement</li>
          </AniLink>
          <AniLink
            to="expertise/apps"
            className={Styles["services-subsection--service"]}
          >
            <h3>Apps</h3>
            <li cover to="/expertise/apps" duration={0.3} bg="black">
              Problem Analysis and Strategy
            </li>
            <li cover to="/expertise/apps" duration={0.3} bg="black">
              Product Design
            </li>
            <li cover to="/expertise/apps" duration={0.3} bg="black">
              Web, Desktop, Mobile (iOS and Android) Development
            </li>
            <li cover to="/expertise/apps" duration={0.3} bg="black">
              Launch and Testing
            </li>
            <li cover to="/expertise/apps" duration={0.3} bg="black">
              Continuous Enhancement and Management
            </li>
          </AniLink>
          <AniLink
            to="expertise/tech"
            className={Styles["services-subsection--service"]}
          >
            <h3>Technology</h3>
            <li cover to="/expertise/technology" duration={0.3} bg="black">
              Data Analytics
            </li>
            <li cover to="/expertise/technology" duration={0.3} bg="black">
              Cloud Technology Assessment and Implementation
            </li>
            <li cover to="/expertise/technology" duration={0.3} bg="black">
              Artificial Intelligence Implementation
            </li>
            <li cover to="/expertise/technology" duration={0.3} bg="black">
              Embedded Systems
            </li>
            <li cover to="/expertise/technology" duration={0.3} bg="black">
              Tech Consultation
            </li>
          </AniLink>
        </div>
      </ScrollAnimation>
    </div>
  </div>
)

export default HomeSectionOne
