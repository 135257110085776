import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import WorkCard from "./WorkCard"
import Styles from "../styles/components/HomeWorkSection.module.scss"

const HomeCaseStudySection = () => {
  const data = useStaticQuery(graphql`
    query homeCaseStudySection {
      allCaseStudiesJson {
        edges {
          node {
            id
            challenge
            outcome
            frontmatter {
              slug
              workTitle
              featureImage
              companyName
            }
          }
        }
      }
    }
  `)
  const latestPosts = data.allCaseStudiesJson.edges.slice(0, 2)
  return (
    <div className="container-padding">
      <div className={Styles["section-line--title"]}>
        <h5>Work</h5>
      </div>
      <div className={Styles["section-intro"]}>
        <h2>
          We're Excited to Have Worked With Great Companies Creating Amazing
          Products Solutions People Love
        </h2>
        <div className={Styles["section-explainer"]}>
          <p>
            PhosMobile Partners with your business to deliver amazing digital
            experiences that drive change and brings growth.
          </p>
          <Link to="case-studies">
            <h5>See More Case Studies</h5>
          </Link>
        </div>
      </div>
      <div className={Styles["section-portfolio"]}>
        {latestPosts.map(({ node: post }) => (
          <WorkCard
            key={post.id}
            companyName={post.frontmatter.companyName}
            workTitle={post.frontmatter.workTitle}
            imageSrc={post.frontmatter.featureImage}
            slug={post.frontmatter.slug}
          ></WorkCard>
        ))}
        <div></div>
      </div>
    </div>
  )
}

export default HomeCaseStudySection
