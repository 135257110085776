import React, { Component } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Styles from "../styles/components/BlogSection.module.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Img from "gatsby-image"

const BlogCard = props => {
  return (
    <div className={`${Styles["section-blogCard"]}`}>
      <a target="_blank" href={props.slug}>
        <img src={props.imageSrc}></img>
        <div className={`${Styles["section-blogCard-text"]}`}>
          <h5>{props.postTag}</h5>
          <h2>{props.postTitle}</h2>
          <p>{props.postExcerpt}</p>
        </div>
      </a>
    </div>
  )
}

class HomeBlogSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
    }
  }

  componentDidMount() {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@hello_45137"
    )
      .then(res => res.json())
      .then(data => {
        const res = data.items //This is an array with the content. No feed, no info about author etc..
        const newPosts = res.filter(res => res.categories.length > 0)

        var firstThreePost = newPosts.slice(0, 3)
        this.setState({
          posts: firstThreePost,
        })
      })
  }

  toText(node) {
    let tag = document.createElement("div")
    tag.innerHTML = node
    node = tag.innerText
    return node
  }

  formatDate(node) {
    var res = node.split(" ")
    return res[0]
  }

  shortenText(text, startingPoint, maxLength) {
    return text.length > maxLength ? text.slice(startingPoint, maxLength) : text
  }

  render() {
    return (
      <div className={`${Styles.container} container-padding`}>
        <div className={Styles["section-line--title"]}>
          <h5>Thoughts</h5>
        </div>
        <div className={Styles["section-intro"]}>
          <h2>
            As a solutions agency we keep our minds busy reading and thinking of
            better ways to work and give value
          </h2>
          <div className={Styles["section-explainer"]}>
            <p>
              Rich collection of wisdom from design, technology and
              understanding the people who use the products.
            </p>
            <Link to="blog">
              <h5>See More Posts</h5>
            </Link>
          </div>
        </div>

        <ScrollAnimation animateIn="fadeIn">
          <div className={`${Styles.cardContainer}`}>
            {this.state.posts.map(post => (
              <BlogCard
                key={post.id}
                postTag={this.formatDate(post.pubDate)}
                q
                postTitle={post.title}
                postExcerpt={
                  this.shortenText(this.toText(post.content), 0, 150) + "..."
                }
                slug={post.link}
                imageSrc={post.thumbnail}
              ></BlogCard>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    )
  }
}

export default HomeBlogSection
